import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule, ORIGIN } from '@angular/fire/functions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { TrendsModule } from './trends/trends.module';

import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';

import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    AppRoutingModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TrendsModule
  ],
  providers: [
    { provide: ORIGIN, useValue: 'https://trend-fisher.web.app' },
    AngularFirestore
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
