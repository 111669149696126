import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HighlightService {

  private _highlighted: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() { }

  get highlighted() {
    return this._highlighted;
  }

  set highlighted(keyword: any) {
    this._highlighted.next(keyword);
  }

}
