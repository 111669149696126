import { NgModule } from '@angular/core';

import { CardComponent } from './card/card.component';
import { HeaderComponent } from './header/header.component';
import { LegendComponent } from './legend/legend.component';
import { TrendsComponent } from './trends.component';
import { FooterComponent } from './footer/footer.component';

import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    CardComponent,
    HeaderComponent,
    FooterComponent,
    LegendComponent,
    TrendsComponent
  ],
  imports: [
    SharedModule
  ],
})
export class TrendsModule { }
