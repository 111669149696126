import { Component, OnInit, Input } from '@angular/core';
import { TableService } from 'src/app/core/services/table.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() source: string;

  constructor(private _table: TableService) { }

  ngOnInit() {
  }

  public setSource(source: string) {
    this._table.activeSource = source;
  }
}
