import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TrendsComponent } from './trends/trends.component';
import { LandingComponent } from './landing/landing.component';


const routes: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path: 'trends',
    component: TrendsComponent
  },
  {
    path: '**',
    redirectTo: 'trends'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
