import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() title: string;
  @Input() date: number;
  @Input() ranks: Array<any>;
  @Input() selected: string;
  @Output() selectKeyword: EventEmitter<string> = new EventEmitter<string>();

  public numberOfColumns = ['', '', '', '', '', ''];

  constructor() { }

  ngOnInit() { }

  getPath(movement: string) {
    return `/assets/${movement}.png`;
  }

  highlight(keyword: string) {
    this.selectKeyword.emit(keyword);
  }

  checkHighlight(keyword: string) {
    return this.selected === keyword;
  }

  checkFade(keyword: string) {
    return this.selected !== keyword && this.selected !== '';
  }

  getTimes(clock: number) {
    let time = [];
    switch (this.title.toLowerCase()) {
      case 'morning':
        time = [
          '6:00 AM',
          '7:00 AM',
          '8:00 AM',
          '9:00 AM',
          '10:00 AM',
          '11:00 AM'
        ];
        return time[clock];
      case 'afternoon':
        time = [
          '12:00 AM',
          '1:00 PM',
          '2:00 PM',
          '3:00 PM',
          '4:00 PM',
          '5:00 PM'
        ];
        return time[clock];
      case 'evening':
        time = [
          '6:00 PM',
          '7:00 PM',
          '8:00 PM',
          '9:00 PM',
          '10:00 PM',
          '11:00 PM'
        ];
        return time[clock];
      case 'night':
        time = [
          '12:00 AM',
          '1:00 AM',
          '2:00 AM',
          '3:00 AM',
          '4:00 AM',
          '5:00 AM'
        ];
        return time[clock];
    }
  }

}
