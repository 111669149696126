import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, ReplaySubject, Observable, BehaviorSubject } from 'rxjs';

import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class TableService implements OnDestroy {

  private _giphyTableSub: Subscription;
  private _tenorTableSub: Subscription;

  private _giphyTables: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _tenorTables: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _activeSource: BehaviorSubject<string> = new BehaviorSubject<string>('tenor');

  constructor(private _fb: AngularFirestore) {
    this._giphyTableSub = this._fb.collection('giphy', ref => ref.orderBy('dateInMS', 'desc').limit(2))
      .valueChanges()
      .subscribe((snapshot: any) => {
        const result = [];
        snapshot.forEach((doc: any) => {
          const wholeDay = this._generateTimes(doc.dateInMS, doc.hours);
          result.push(wholeDay);
        });
        this._giphyTables.next(result);
      });

    this._tenorTableSub = this._fb.collection('trending', ref => ref.orderBy('dateInMS', 'desc').limit(2))
      .valueChanges()
      .subscribe((snapshot: any) => {
        const result = [];
        snapshot.forEach((doc: any) => {
          const wholeDay = this._generateTimes(doc.dateInMS, doc.hours);
          result.push(wholeDay);
        });
        this._tenorTables.next(result);
      });
  }

  ngOnDestroy(): void {
    if (this._giphyTableSub) { this._giphyTableSub.unsubscribe(); }
    if (this._tenorTableSub) { this._tenorTableSub.unsubscribe(); }
  }

  get giphyTables(): Observable<any> {
    return this._giphyTables;
  }

  get tenorTables(): Observable<any> {
    return this._tenorTables;
  }

  get activeSource(): any {
    return this._activeSource;
  }

  set activeSource(source: any) {
    this._activeSource.next(source);
  }

  private _generateTimes(date: number, hours: Array<any>) {
    let i = 1;
    const today = [];
    const morning = [];
    const afternoon = [];
    const evening = [];
    const night = [];
    for (const hour in hours) {
      if (hours.hasOwnProperty(hour)) {
        today.push([hour, hours[hour]]);
        if (i <= 6) {
          morning.push([hour, hours[hour]]);
        } else if (i > 6 && i <= 12) {
          afternoon.push([hour, hours[hour]]);
        } else if (i > 12 && i <= 18) {
          evening.push([hour, hours[hour]]);
        } else if (i > 18 && i <= 24) {
          night.push([hour, hours[hour]]);
        }
        i++;
      }
    }

    const wholeDay = {
      date,
      today,
      morning,
      afternoon,
      evening,
      night
    };

    return wholeDay;
  }

}
