import { Component, OnInit } from '@angular/core';
import { TableService } from '../core/services/table.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(private _router: Router, private _table: TableService) { }

  ngOnInit() { }

  public setSource(source: string) {
    this._table.activeSource = source;
    this._router.navigate(['/', 'trends']);
  }

}
