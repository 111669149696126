import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { TableService } from 'src/app/core/services/table.service';
import { HighlightService } from 'src/app/core/services/highlight.service';
import { transition, style, stagger, query, animate, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.scss'],
  animations: [
    trigger('cardEntry', [
      transition(':enter', [
        query('.card', [
          style({ opacity: 0, transform: 'translateX(-50px)' }),
          stagger(-100, [
            animate('600ms ease-in-out', style({ opacity: 1, transform: 'none' }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class TrendsComponent implements OnDestroy {
  @HostBinding('@cardEntry')

  private _tableSub: Subscription;
  private _hightlightSub: Subscription;
  private _sourceSub: Subscription;

  public tables: Array<any> = [];
  public ready = false;
  public selectedKeyword = '';
  public showClear: boolean;
  public source: string;

  constructor(private _highlight: HighlightService, private _table: TableService) {

    this._sourceSub = this._table.activeSource.subscribe((source: string) => {
      this.source = source;

      if (source === 'tenor') {
        console.log('Creating a tenor instance...');
        this._tableSub = this._table.tenorTables.subscribe((tablechanges: any) => {
          this.tables = tablechanges;
          this.ready = true;
        });
      } else {
        console.log('Creating a GIPHY instance...');
        this._tableSub = this._table.giphyTables.subscribe((tablechanges: any) => {
          this.tables = tablechanges;
          this.ready = true;
        });
      }

      this._hightlightSub = this._highlight.highlighted.subscribe((selectedKeyword: string) => {
        this.selectedKeyword = selectedKeyword;
        this.showClear = selectedKeyword !== '';
      });
    });
  }

  ngOnDestroy() {
    if (this._sourceSub) { this._sourceSub.unsubscribe(); }
    if (this._tableSub) { this._tableSub.unsubscribe(); }
    if (this._hightlightSub) { this._hightlightSub.unsubscribe(); }
  }

  public changeKeyword(keyword: string) {
    if (this.selectedKeyword === keyword) {
      this._highlight.highlighted = '';
    } else {
      this._highlight.highlighted = keyword;
    }
  }

  public removeFocus() {
    this._highlight.highlighted = '';
  }

}
